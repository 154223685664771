<template>
<div class="stu-analysis">
<!--  <div class="group-list">-->
<!--    <div class="active">Java1班</div>-->
<!--    <div>基础1班</div>-->
<!--    <div>网络3班</div>-->
<!--  </div>-->
  <div class="stu-analysis-top">
    <el-form :inline="true" :model="searchForm">
      <el-form-item label="状态">
        <el-radio-group v-model="searchForm.is_check" v-for="item in ScoringStatus" :key="item.id" @change="getList()">
          <el-radio :label="item.id" style="margin-right: 20px;">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
<!--    <Button type="primary" @click="exportFile">导出</Button>-->
  </div>
<!--  <div class="stu-analysis-top">-->
<!--    <Button type="primary" @click="exportFile">导出</Button>-->
<!--  </div>-->

  <Table :columns="columns" :data="dataList">
    <template slot-scope="{ row, index }" slot="op">
      <Button type="primary"  style="margin-right: 5px" v-if="row.status == 4" @click="goMark(row)">批卷</Button>
<!--      <Button type="primary"  style="margin-right: 5px" @click="goMark(row)">批卷</Button>-->

      <Button type="primary"  style="margin-right: 5px" @click="goStuDetail(row)" v-if="row.status == 2 || row.status == 4 || row.status == 5">答题详情</Button>
      <Button v-if="row.status == 0 || row.status == 1">未交卷</Button>
    </template>
  </Table>
  <div class="page">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
</div>
</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "stuAnalysis",
  data(){
    return{
      columns:[
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '学生名称',
          render:(h,params)=>{
            return h('span',params.row.user && params.row.user.nickname)
          }
        },
        {
          title: '手机号',
          render:(h,params)=>{
            return h('span',params.row.user && params.row.user.mobile)
          }
        },
        {
          title: '交卷时间',
          render:(h,params)=>{
            return h('span', util.timeFormatter(
                    new Date(+params.row.updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '状态',
          render:(h,params)=>{
            return h('span',this.statuses[params.row.status])
          }
        },
        {
          title: '总分数',
          key: 'score'
        },
        {
          title: '客观题分数',
          key: 'auto_check_score'
        },
        {
          title: '主观题分数',
          key: 'hand_check_score'
        },
        // {
        //   title: '及格',
        //   render:(h,params)=>{
        //     return h('span',(params.row.train && (params.row.train.pass_score >= params.row.score)) ? '否' : '是')
        //   }
        // },
        // {
        //   title: '答题时长',
        //   key: 'use_time'
        // },


        {
          title: '操作',
          key: 'address',
          slot:'op'

        }
      ],
      dataList:[
        {
          id:'1',
          name:'1111',
          address:'123'
        }
      ],
      searchForm:{
        is_check:"-1"
      },
      ScoringStatus:[
        {
          id:"-1",
          name:"全部"
        },
        {
          id:"0",
          name:"未阅卷"
        },
        {
          id:"1",
          name:"已阅卷"
        }
      ],
      total:0,
      page:1,
      pageSize:10,
      examineId:'',
      statuses:{},
    }
  },
  mounted(){
    this.examineId = this.$route.query.id;
    this.getList()
  },
  methods:{
    getList(){
      const { searchForm }=this;
      let params={
        examine_id:this.examineId,
        page:this.page,
        page_size:this.pageSize,
        ...searchForm
      };
      this.api.course.datasetExamineUserList(params).then((res)=>{
        console.log(res,'ddddd')
        this.dataList = res.list;
        this.total = Number(res.count);
        this.statuses = res.statuses;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    goStuDetail(data){
      this.$router.push({
        path:'/trainingcamp/stuExamDetail',
        query:{
          id:data.id,
        }
      })
    },
    exportFile(){
      let params = {
        examine_id:this.examineId,
      }
      this.api.course.exportUserList(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
        })
      })
    },
    goMark(data){
      this.$router.push({
        path:'/teacher/trainingcamp/exam',
        query:{
          id:data.train.id,
          isResult:true,
          isMark:true,
          user_id:data.user_id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.stu-analysis{
  padding: 20px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #333333;
  .stu-analysis-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .group-list{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    >div{
      margin-right: 10px;
      padding: 0 22px;
      height: 42px;
      background: #F6F7FA;
      border-radius: 6px;
      line-height: 42px;
      font-weight: bold;
      cursor: pointer;
    }
    .active{
      background: #4A79FF;
      color: #FFFFFF;
    }
  }
  .page{
    margin-top: 20px;
    text-align: center;
  }
}
</style>
