<template>
<div class="paper-analysis">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="paper-top">
    <div class="tab-list">
      <div v-for="item in tabList" :key="item.id" :class="curTab.id == item.id ? 'active' : ''" @click="changeTab(item)">{{item.name}}</div>
    </div>
    <div class="paper-top-btns">
      <Button type="primary" @click="exportFile('data_list')">导出全部试题</Button>
      <Button type="primary" @click="exportFile('train_user')">导出学生答题成绩</Button>
      <Button type="primary" @click="exportFile('user_answer_list')">导出学生答题记录</Button>

    </div>
  </div>
  <component :is="curTab.componentName"></component>
</div>
</template>

<script>
import GlobalAnalysis from './globalAnalysis';
import StuAnalysis from './stuAnalysis';
import DataAnalysis from './dataAnalysis';
import util from "@/utils/tools.js";
export default {
  name: "paperAnalysis",
  data(){
    return {
      tabList:[
        {
          name:'整体分析',
          id:1,
          componentName:'GlobalAnalysis'
        },
        {
          name:'学生答题',
          id:2,
          componentName:'StuAnalysis'
        },
        {
          name:'试题分析',
          id:3,
          componentName:'DataAnalysis'
        },
      ],
      curTab:{
        name:'整体分析',
        id:1,
        componentName:'GlobalAnalysis'
      },
      examineId:'',
    }
  },
  components:{
    GlobalAnalysis,
    StuAnalysis,
    DataAnalysis
  },
  created(){
    this.examineId = this.$route.query.id;
  },
  methods:{
    changeTab(data){
      this.curTab = data;
    },
    exportFile(op){
      let params = {
        examine_id:this.examineId,
        op:op
      }
      this.api.course.exportUserList(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.paper-analysis{
  margin: 20px;
  .paper-top{
    margin-top: 20px;
    height: 64px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #EEEEEE;
    .tab-list{
      margin:0 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100%;
      //border-bottom: 2px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      >div{
        padding: 0 5px;
        margin-right: 30px;
        height: 54px;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
    .paper-top-btns{
      >button{
        margin-right: 20px;
      }
    }
  }
}
</style>
