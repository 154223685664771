<template>
<div class="data-analysis">
  <Table :columns="columns" :data="dataList">
  </Table>
  <div class="page">
    <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
  </div>
</div>
</template>

<script>
export default {
  name: "dataAnalysis",
  data(){
    return{
      columns:[
        {
          title: 'ID',
          key: 'id',
          width:'80'
        },
        {
          title: '题目',
          key: 'title'
        },
        {
          title: '题型',
          key: 'address',
          width:'120',
          render:(h,params)=>{
            return h('span',this.dataTypes[params.row.type])
          }
        },
        {
          title: '答题人数',
          key: 'address',
          width:'120',
          render:(h,params)=>{
            return h('span',params.row.trainData && params.row.trainData.totalCount)
          }
        },
        {
          title: '答对人数',
          key: 'address',
          width:'120',
          render:(h,params)=>{
            return h('span',params.row.trainData && params.row.trainData.rightCount)
          }
        },
        {
          title: '正确率',
          key: 'address',
          width:'120',
          render:(h,params)=>{
            return h('span',params.row.trainData && params.row.trainData.rightRate + '%')
          }
        },
      ],
      dataList:[
        {
          id:'1',
          name:'1111',
          address:'123'
        }
      ],
      total:0,
      page:1,
      pageSize:10,
      examineId:'',
      dataTypes:{},
    }
  },
  mounted(){
    this.examineId = this.$route.query.id;
    this.getList()
  },
  methods:{
    getList(){
      let params={
        examine_id:this.examineId,
        page:this.page,
        page_size:this.pageSize,
      };
      this.api.course.examineDataList(params).then((res)=>{
        this.dataList = res.list;
        this.total = Number(res.count);
        this.dataTypes = res.data_types;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.data-analysis{
  padding: 20px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #333333;
  .page{
    margin-top: 20px;
    text-align: center;
  }
}
</style>
